import React from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API, selectorKeys } from '@src/constants/api'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { hiringProcessSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'

const RecruitmentGroupsGeneral = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeHiringProcessPreferences,
  )

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="Recruitment groups" />
          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable recruitment groups',
                description:
                  'After enabling recruitment groups you will be able to assign certain specialisations to recruitment teams in your organisation. Owners of said teams will be used as “Lead Recruiter” in the approval flow for job postings of specialisations assigned to their teams, if approval is enabled in settings. Also, recruitment team owners will be assigned as default recruiters to requisitions for the specialisations their teams own.',
              }}
              name="enable_recruitment_groups"
              disabled={disableEdit}
            />
            <VStack p="s-16" space="s-8">
              <LapeRadioSelectInput
                name="default_lead_recruiter"
                label="Default lead recruiter"
                selector={selectorKeys.employee}
                message="Default Lead Recruiter is the person who, by default, approves Job Postings as “Lead Recruiter” if Job Postings are set to require “Lead recruiter approval” in the Job Postings settings. Also, lead recruiter will be assigned as a default recruiter to all new requisitions."
              />
            </VStack>
          </Widget>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.HIRING_PROCESS_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Recruitment groups',
    path: ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS,
    url: ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS,
    canView: [
      PermissionTypes.ViewHiringProcessPreferences,
      PermissionTypes.ChangeHiringProcessPreferences,
    ],
    component: RecruitmentGroupsGeneral,
  },
]

export const RecruitmentGroups = () => {
  return <SettingsForm routes={routes} api={hiringProcessSettings} />
}
