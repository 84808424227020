import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { CountryHiringInterface } from '@src/interfaces/settings'
import {
  countriesCountryHiringColumn,
  countriesHiringLocationColumn,
  countriesHiringEmploymentRiskColumn,
  countriesHiringPreferredSpecialisationsColumn,
  countriesHiringHiringColumn,
  countriesHiringActionsColumn,
} from '@src/constants/columns/countryHiring'
import { useTable } from '@src/components/Table/hooks'
import { countryHiringRequests } from '@src/api/settings'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const row: RowInterface<CountryHiringInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING_FORM, { id })),
  cells: [
    { ...countriesCountryHiringColumn, width: 130 },
    { ...countriesHiringLocationColumn, width: 120 },
    { ...countriesHiringEmploymentRiskColumn, width: 140 },
    { ...countriesHiringPreferredSpecialisationsColumn, width: 200 },
    { ...countriesHiringHiringColumn, width: 90 },
    { ...countriesHiringActionsColumn, width: 90 },
  ],
}

export const CountryHiring = () => {
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'country_has_locations',
      filters: [
        {
          id: 'true',
          name: 'true',
        },
      ],
      nonResettable: true,
    },
  ]
  const table = useTable<CountryHiringInterface>(countryHiringRequests, initialFilter)
  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.SETTINGS.REQUISITIONS.LIST}
        title="Hiring countries"
        subtitle="Define your hiring policy for each of locations"
      />
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="Hiring countries"
            subtitle="Here you can define which locations can be added to a requisition"
          />
          <AdjustableTable<CountryHiringInterface>
            name={TableNames.CountryHiring}
            useWindowScroll
            dataType="Hiring country"
            row={row}
            {...table}
            hideCount
            noDataMessage="Hiring countries will appear here."
          />
        </AutoStepper>
      </PageBody>
    </PageWrapper>
  )
}
